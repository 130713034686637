/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useCallback, useEffect, useMemo } from "react";
import { useRouter } from "next/router";

import {
  checkIfContainsPath,
  UserSubscriptionStatus,
  useSubscriptionsSelector,
  useUserSubprofilesSelector,
} from "@nf/common";
import {
  useReplacePage,
  useRoutePathBuild,
  useSubProfileChangeEvent,
  useRedirectToConsentPage,
} from "../../hooks";

import { AppConfig, ROUTES, mainChannels, paymentPaths } from "@nf/constants";
interface IRoutProtectorProps {
  children: JSX.Element;
  isLoggedIn?: boolean;
}

export const RouteProtector = ({
  children,
  isLoggedIn,
}: IRoutProtectorProps) => {
  const router = useRouter();
  const { subscriptions } = useSubscriptionsSelector();
  const { Data: subprofilesState } = useUserSubprofilesSelector();
  const routePrefix = useRoutePathBuild();
  useSubProfileChangeEvent();

  const currentSubprofile = subprofilesState?.currentSubprofile;

  const redirectToPage = useReplacePage();

  const shouldRedirectToKidsChannel = useMemo(
    () =>
      currentSubprofile?.Child &&
      checkIfContainsPath(router.asPath, mainChannels),
    [router.asPath, currentSubprofile?.Child]
  );

  const onPopState = useCallback(() => {
    if (
      currentSubprofile?.Child &&
      checkIfContainsPath(location.href, mainChannels)
    ) {
      redirectToPage(`${AppConfig.ChannelKids}`);

      history.pushState(
        null,
        document.title,
        `${location.origin}${router.asPath}`
      );
    }
  }, [currentSubprofile?.Child, router.asPath]);

  useEffect(() => {
    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  useRedirectToConsentPage(isLoggedIn);

  useEffect(() => {
    const isPaymentPath = checkIfContainsPath(router.asPath, paymentPaths);
    const hasActiveSubscription = subscriptions?.some(
      (subscription) =>
        subscription.SubscriptionStatus === UserSubscriptionStatus.ACTIVE ||
        subscription.SubscriptionStatus === UserSubscriptionStatus.CANCELLED // still active until the end of subscription period
    );
    const hasPausedSubscription = subscriptions?.some(
      (subscription) =>
        subscription.SubscriptionStatus === UserSubscriptionStatus.HOLD
    );

    if (isPaymentPath && (hasActiveSubscription || hasPausedSubscription)) {
      redirectToPage(ROUTES.SETTINGS_SUBSCRIPTION);
    }
  }, [redirectToPage, router.asPath, subscriptions]);

  useEffect(() => {
    if (
      isLoggedIn &&
      (router.asPath === ROUTES.BASE ||
        router.asPath.startsWith(`${ROUTES.BASE}?`) || // When redirected from Google or from anywhere
        router.asPath === `${routePrefix}`)
    ) {
      redirectToPage(currentSubprofile?.InitialHomePage ?? ROUTES.HOME, true);
    }
  }, [
    isLoggedIn,
    router.asPath,
    routePrefix,
    redirectToPage,
    currentSubprofile?.InitialHomePage,
  ]);

  useEffect(() => {
    if (shouldRedirectToKidsChannel) {
      redirectToPage(`${AppConfig.ChannelKids}`, true);
    }
  }, [redirectToPage, shouldRedirectToKidsChannel]);

  useEffect(() => {
    const urlCountryRegEx = /(\/[a-z]{2}\/)/gi;
    if (
      !urlCountryRegEx.test(router.asPath) &&
      (router.asPath.includes(ROUTES.MOVIE_DETAILS_SCREEN) ||
        router.asPath.includes(ROUTES.SERIES_DETAILS_SCREEN) ||
        router.asPath.includes(ROUTES.COLLECTION_DETAILS_SCREEN))
    ) {
      redirectToPage(`${router.asPath}`, true);
    }
  }, [router.asPath, redirectToPage]);

  return children;
};

export default RouteProtector;
